import React from 'react'
import Layout from '../components/layout'
import Header from '../components/header'
import ContactForm from '../components/ContactForm'
import SEO from '../components/SEO'
import DateListRendered from '../components/DateListRendered'

const Contact = (props) => (
  <Layout>
    <SEO />
    <Header siteTitle={'Norfolk Current'} />

    <section className="bg-white">
      <div className="pt-8 pb-8 lg:pt-0 lg:pb-16 px-4 mx-auto max-w-screen-lg">
        <h1 className="mb-4 text-3xl font-semibold text-center text-slate-950 sm:mb-10">Contact Us</h1>
        <p className="mb-8 lg:mb-14 font-light text-center text-slate-700 sm:text-xl">For enquiries or quotes, please fill out the contact form below and we'll get back to you promptly.</p>
        <div className="md:grid md:grid-cols-2 md:gap-10">

          <ContactForm />

          <div className="mb-20 mb:mb-0 md:ml-10">
            <h3 className="mb-4 text-2xl font-semibold md:hidden">Our Information</h3>
            <p className=" text-lg font-semibold leading-6 text-slate-950">
              Phone number
            </p>
            <p className="mt-2 text-lg text-slate-950 hover:underline">
              <a href="tel:07469 469586">07469 469586</a>
            </p>
            <p className="mt-5 text-lg font-semibold leading-6 text-slate-950 ">
              Email
            </p>
            <p className="mt-2 text-lg text-slate-950 hover:underline">
              <a href="mailto:ciarant@norfolkcurrent.co.uk">ciarant@norfolkcurrent.co.uk</a>
            </p>
            <p className="mt-5 text-lg font-semibold leading-6 text-slate-950">
              Address
            </p>
            <p className="mt-2 text-lg text-slate-950">
              117 Marlborough Road <br />
              Norwich <br />
              NR3 4PL
            </p>
            <p className="mt-5 text-lg font-semibold leading-6 text-slate-950">
              Hours
            </p>
            <p className="mt-2 text-lg text-slate-950">
              Monday - Friday: 9am - 5pm
            </p>

            <p className="mt-4">Our business operates primarily in Norfolk, but we're happy to discuss opportunities elsewhere. Please contact us for more details.</p>
          </div>
        </div>

        <section className="bg-white">
          <div className="mb-20 mb:mb-0">
            <h3 className="mb-4 text-2xl font-semibold">Schedule an appointment</h3>
            <p className="mt-2 text-lg text-slate-950">
              Select what service you need, then select the date and time that works best for you. We will get back to you to confirm the appointment.
            </p>
            <DateListRendered/>

          </div>
        </section>

      </div>
    </section>

  </Layout>
)

export default Contact
