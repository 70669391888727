import React, { useEffect } from 'react'

function DatelistComponent () {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://datelist.io/dist/datelist/1.3.6/js/app.js'
    script.async = false

    script.onload = () => {
      dlist('c49f5c33-087e-416a-bd43-f82491ef5ad0')

      // Add your custom styles to the head of the document
      const style = document.createElement('style')
      style.textContent = `
        .dist-widget__header l {
          display: none !important;
        }

        .dlist-widget__header__titles {
          display: none !important;
        }

        .dlist-widget {
          padding-left: 0px !important;
        }
      `
      document.head.appendChild(style)
    }

    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [])

  return (
    <div>
      <div id="dlist"></div>
    </div>
  )
}

export default DatelistComponent
