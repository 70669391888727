import React, { useState } from 'react'

export default function ContactForm () {
  const emailAPIURL = 'https://acdo5psr2k.execute-api.eu-west-1.amazonaws.com/'
  // const emailAPIURL = 'http://localhost:3000/'

  const [formData, setFormData] = useState({
    email: '',
    name: '',
    contents: ''
  })

  const [requestStatus, setRequestStatus] = useState('idle')

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log(formData)
    setRequestStatus('loading')

    try {
      const response = await fetch(emailAPIURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...formData
        })
      })

      if (!response.ok) {
        throw new Error('HTTP error ' + response.status)
      }

      const data = await response.json()
      setRequestStatus('success')

      console.log(data) // You can remove this line after confirming that the request works
    } catch (error) {
      console.error('Error:', error)
      setRequestStatus('error')
    }
  }

  const buttonText = {
    idle: 'Send message',
    loading: 'Sending...',
    success: 'Message sent!',
    error: 'Error. Try again.'
  }

  return (
    <form onSubmit={handleSubmit} action="#" className="space-y-8 mb-20">
        <div>
            <label htmlFor="email" className="block mb-2 font-semibold leading-6 text-slate-950">Your email</label>
            <input
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            type="email" id="email" value={formData.email} className="shadow-sm bg-gray-50 border border-gray-300 text-slate-950 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="example@example.com" required />
        </div>
        <div>
            <label htmlFor="name" className="block mb-2 font-semibold leading-6 text-slate-950">Name</label>
            <input
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
             type="text" value={formData.name} id="name" className="block p-3 w-full text-sm text-slate-950 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500" placeholder="What's your name" required />
        </div>
        <div className="sm:col-span-2">
            <label htmlFor="message" className="block mb-2 font-semibold leading-6 text-slate-950">Your message</label>
            <textarea
              onChange={(e) => setFormData({ ...formData, contents: e.target.value })}
            id="message" value={formData.contents} rows="6" className="block p-2.5 w-full text-sm text-slate-950 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Leave a comment..."></textarea>
        </div>
        <button type="submit" disabled={requestStatus === 'loading'} className={`py-3 px-5 text-sm font-medium tracking-wide text-center text-white rounded-lg ${requestStatus === 'loading' ? 'bg-gray-500' : 'bg-blue-950'} sm:w-fit hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300`}>
          {buttonText[requestStatus]}
        </button>
      </form>
  )
}
